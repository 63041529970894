<template>
  <div>
    <list-layout ref="govCourse" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <course-list-filter is-gov :filterForm="filterForm" :filter-change="ok" />
      </template>
<!--      <template #item_sort="{row}">{{row.sort}}</template>-->
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovCourses } from "@/modules/gov/api/course-library";
import CourseListFilter from "@/modules/online-classroom/components/course-manage/index/Filter";

export default {
  components: {CourseListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        tab: "normal",
        create_start_time: -1, //创建开始时间
        create_end_time: -1, //创建结束时间
        keyword: "", // 课程名称搜索
        category_id: "", // 课程分类
        category_name: "", // 课程分类名称
        user_keyword: "", // 创建人搜索
        type: -1, //课程类型
        show_type: -1, //显示返回
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        { label: "ID", prop: "id", width: 100 },
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "课程名称", prop: "name", minWidth: 120, tooltip: true },
        { label: "分类", prop: "category_name", minWidth: 120 },
        { label: "课程来源", prop: "source_text", minWidth: 120 },
        { label: "课程类型", prop: "type_text", minWidth: 120 },
        { label: "课程数", prop: "reses_count", minWidth: 120, sort: "custom" },
        { label: "已学人数", prop: "learned_count", minWidth: 120 },
        { label: "创建人", prop: "creator_name", minWidth: 120, tooltip: true },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 180,
          sort: "custom",
        },
        { label: "显示范围", prop: "show_type_text", minWidth: 120 },
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovCourses(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govCourse.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
